import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { media } from '../../Products/ui/common';
import { colors } from '../../../ui/common';

export const Wrap = styled.div`
  overflow: auto;
  min-width: 540px;
  margin: ${(props) => (props.margins ? '14px 0' : '0 0')};
  ${media.tablet`
      margin: ${(props) => (props.margins ? '22px 0' : '0 0')};
  `}
`;
export const List = styled.div`
  padding-bottom: ${(props) => (props.margins ? '32px' : '0px')};
`;
export const Row = styled.div`
  display: flex;
  margin-bottom: 7px;
`;
export const Block = styled.div`
  width: 25%;
  padding: 11px 16px;
  box-sizing: border-box;
  background: ${colors.color07};
  display: flex;
  align-items: center;
  flex: 1 0 0;

  &:nth-of-type(2) {
    border-radius: 5px 0 0 5px;
    border-right: 1px solid rgba(1, 62, 62, 0.08);
  }
  &:last-of-type {
    border-radius: 0 5px 5px 0;
    border-left: 1px solid rgba(1, 62, 62, 0.08);
  }
  img {
    display: block;
    margin: auto;
  }
  div {
    display: block;
    margin: auto;
  }
`;
export const Title = styled(Block)`
  display: flex;
  background: transparent;
  padding-left: 0;
  min-width: 166px;
  max-width: 190px;
  box-sizing: border-box;
  img {
    margin: 0;
    margin-right: 11px;
  }
  div {
    display: block;
    margin: 0;
  }
`;
export const Header = styled.div`
  display: flex;
`;
export const Cell = styled.div`
  width: 25%;
  text-align: center;
  padding-bottom: 22px;
  flex: 1 0 0;
`;
export const Image = styled(GatsbyImage)`
  width: 78px;
  margin: auto;
  ${media.medium`
    width: 96px;
  `}
`;
export const Name = styled.p`
  font-weight: 500;
  font-size: 16px;
  color: ${colors.color06};
  margin: 10px 0;
`;
export const Text = styled.div`
  font-weight: 500;
  font-size: 13px;
  color: ${colors.color01};
  word-break: break-word;
  opacity: 0.8;
  line-height: 1.7;
  ${media.phone`
    font-size: 15px;
  `}
`;

export const CenterText = styled.div`
  font-weight: 500;
  font-size: 13px;
  color: ${colors.color01};
  opacity: 0.8;
  line-height: 1.7;
  text-align: center;
  white-space: pre-line;
  ${media.phone`
    font-size: 15px;
  `}
`;
export const Subtitle = styled.div`
  font-size: 14px;
  color: ${colors.color05};
`;
