import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import isString from 'lodash/isString';
import {
  List,
  Block,
  Row,
  Title,
  Header,
  Cell,
  Image,
  Name,
  Subtitle,
  Wrap,
  Text,
  CenterText,
} from './styled';
import checkIcon from '../../../images/components/common/ComparisonTable/check.svg';
import uncheckIcon from '../../../images/components/common/ComparisonTable/uncheck.svg';

function ComparisonTable({ header, images, data, margins = true }) {
  return (
    <Wrap margins={margins}>
      <Header>
        <Title />
        {header.map((el, key) => (
          // eslint-disable-next-line react/no-array-index-key
          <Cell key={key}>
            <Image image={images[key]} alt="" />
            <Name>{el.title}</Name>
            <Subtitle>{el.subtitle}</Subtitle>
          </Cell>
        ))}
      </Header>
      <List margins={margins}>
        {data.map((item) => (
          <Row key={item.title}>
            <Title>
              <img src={item.image} alt="" />
              <Text>
                <FormattedMessage id={item.title} />
              </Text>
            </Title>
            {item.values.map((el, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <Block key={i}>
                {/* eslint-disable-next-line no-nested-ternary */}
                {isString(el) ? (
                  <CenterText>
                    <FormattedMessage id={el} />
                  </CenterText>
                ) : el ? (
                  <img src={checkIcon} alt="check" />
                ) : (
                  <img src={uncheckIcon} alt="uncheck" />
                )}
              </Block>
            ))}
          </Row>
        ))}
      </List>
    </Wrap>
  );
}
export default ComparisonTable;

ComparisonTable.propTypes = {
  images: PropTypes.arrayOf(PropTypes.any),
  header: PropTypes.arrayOf(PropTypes.any),
  data: PropTypes.arrayOf(PropTypes.any),
  margins: PropTypes.bool,
};

ComparisonTable.defaultProps = {
  images: [],
  header: [],
  data: [],
  margins: true,
};
